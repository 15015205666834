<template>
  <div class="modal" :class="{ 'is-active': active }">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ slideData.name || 'Slide' }}</p>
        <span class="material-icons close_modal_icon" @click="$emit('close-modal')">close</span>
      </header>
      <section class="modal-card-body p-6">
        <div class="columns is-multiline">
          <div class="column is-9">
            <div class="field control">
              <label class="label">Link de botón</label>
              <input
                class="input"
                type="text"
                placeholder="Link"
                v-model="localLink"
                name="localLink"
                v-validate="{ url: { require_protocol: true } }"
                data-vv-as="del link"
              >
              <span v-show="vverrors.has('localLink')" class="help is-danger">{{ vverrors.first('localLink') }}</span>
            </div>
          </div>
          <div class="column is-3">
            <div class="is-flex is-flex-direction-column is-align-items-flex-start">
              <label class="label mb-4">Activo</label>
              <span>
                <input id="switchStatus" type="checkbox" name="switchStatusStatus" class="switch is-rounded is-small" v-model="localStatus" :checked="localStatus ? 'checked' : ''">
                <label for="switchStatus"></label>
              </span>
            </div>
          </div>
          <div class="column is-6">
            <card-upload-file
              class="card_upload"
              :whitBorder="true"
              nameInputFile="imageSlide"
              titleCard="Imagen (2300x1400)"
              :previewImage="previewImageSlide"
              :setFile="setImageSlide"
              :modeEdit="true"
              validate="ext:jpeg,jpg,png|size:3000"
              accept=".jpeg,.jpg,.png,"
            />
          </div>
        </div>
      </section>
      <footer class="modal-card-foot is-justify-content-center">
        <button
          class="button button_micro_dark_outlined"
          :disabled="loading"
          @click="$emit('close-modal')"
        >
          CANCELAR
        </button>
        <button
          class="button button_micro_dark"
          @click="actionModal()"
          :class="{ 'is-loading' : loading }"
          :disabled="loading"
        >
          GUARDAR
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalSlide',
  components: {
    CardUploadFile: () => import('@/components/UI/CardUploadFile.vue')
  },
  data () {
    return {
      localStatus: this.slideData.active,
      localLink: this.slideData.link || '',
      previewImageSlide: this.slideData.image ? `${process.env.VUE_APP_URL_FILES}${this.slideData.image}` : null,
      imageFileMain: null
    }
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    slideData: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: 'edit'
    }
  },
  methods: {
    // Imagen
    async setImageSlide (event) {
      if (event) {
        const [file] = event.target.files
        // Preview
        if (file) {
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = e => {
            this.previewImageSlide = e.target.result
          }
          this.imageFileMain = { key: event.target.name, file }
        }
      }
    },
    async actionModal () {
      if (await this.$validator.validateAll()) {
        const data = {
          slideId: this.slideData.id,
          link: this.localLink,
          active: this.localStatus,
          image: this.imageFileMain
        }
        if (this.mode === 'add') {
          this.$emit('add-slide-action', data)
          console.log('add')
        } else {
          this.$emit('set-data-action', data)
          console.log('edit')
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .modal-card {
    border-radius: 18px;
  }
  .modal-card-head, .modal-card-foot {
    background-color: white;
  }
  .modal-card-foot {
    border-top: none;
    justify-content: flex-end;
  }

  .button {
    font-size: 14px;
    width: 30%;
  }

  .card_upload {
    /deep/ .card-header .card-header-title{
      padding: 0 0 10px 0;
    }
    /deep/ .file-cta {
      width: 100%;
    }
    /deep/ .file-label {
      display: table;
      white-space: normal;
      line-height: 1;
    }
  }
</style>
